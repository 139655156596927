import * as React from "react"
import { Link, navigate } from "gatsby";
import Card from '@mui/material/Card';
import { CardActionArea, CardMedia, Typography, Pagination, AppBar, Toolbar, IconButton} from '@mui/material';
import CardContent from '@mui/material/CardContent'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as queryString from "query-string";



const firebaseConfig = {
  apiKey: "AIzaSyAphO2oMz0XPzeo4dFHHNmGTcxgxW8hoqI",
  authDomain: "natural-dog-food.firebaseapp.com",
  projectId: "natural-dog-food",
  storageBucket: "natural-dog-food.appspot.com",
  messagingSenderId: "367062847841",
  appId: "1:367062847841:web:828b59f872eb889e66f520",
  measurementId: "G-42E61CHK1M"
};
async function redirectToCheckout(product, price, multiplier, weight, zipcode){
  
  const rawResponse = await fetch('/checkout', {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({"data":{
      "product": product,
      "price": price,
      "multiplier": multiplier,
      "weight": weight,
      "zipcode": zipcode
  }})}
  );

  rawResponse.json().then(jsonFile => window.location.replace(jsonFile[0]));

}

async function getZipcodesFromApi() {
  try {
    let response = await fetch('https://raw.githubusercontent.com/millbj92/US-Zip-Codes-JSON/master/USCities.json');
    let responseJson = await response.json();
    return responseJson;
   } catch(error) {
    console.error(error);
  }
}



const DisplayOptionsPage = ({location}) => {
  const price2= queryString.parse(location.search);
  var product = price2["recipeType"];
  var price = price2["price"];
  var weight = price2["dw"];


  var [errorState, setErrorState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputState, setInputState] = React.useState(null);
  const [days, setDays] = React.useState(0);
  var [errorText, setErrorText] = React.useState("");

  const handleClickOpen = daysProp => {
    setOpen(true);
    setDays(daysProp);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTextChange = e => {
    setInputState({ input: e.target.value });
  }
  const allowedStates = ["WY","CO","AZ","NE", "NM", "KS","OK","TX", "AK","LA","AL", "MS", "TN", "OH",  "GA","WI","MI","PA", "SC", "NC"];
  const checkZipCodes = days => {
    var zip = inputState["input"];
    if (isNaN(zip)) {
      setErrorText("Please enter a valid zip code.");
      setErrorState(true);
    } else {
    fetch("https://raw.githubusercontent.com/millbj92/US-Zip-Codes-JSON/master/USCities.json")
    .then(response => response.json())
    .then((jsonData) => {
      for (var i = 0; i < jsonData.length; i++) {
        if (jsonData[i]["zip_code"] == zip) {
          var state = jsonData[i]["state"];
          if (allowedStates.indexOf(state) > -1) {
            redirectToCheckout(product, price, days, weight, jsonData[i]["zip_code"]);
          } else {
            setErrorText("We do not serve this ZIP Code.");
            setErrorState(true);
          }
        }
      }
    })
    }
  }
  const height = 300; 
    
  //if (location.state){
    const handleChange = (event, value) => {
      if (value == 1) {
        navigate('/');
      } else if (value == 2) {
        navigate('/selectWeight ', {state:{"recipe":product}})
      }
    }
   
    //var product = location.state.stateValues[1];
    //var price = location.state.stateValues[0];
    //var weight = location.state.stateValues[2];
    //console.log(weight);
    var disp_recipe;
    var image_url;
    if (product == "chicken_beef") {
      disp_recipe = "Chicken Beef";
      image_url = "https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/chicken-beef.png";
    } else if (product == "chicken") {
      disp_recipe = "Chicken";
      image_url = "https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/chicken.png";
    } else if (product == "beef" ) {
      disp_recipe = "Beef";
      image_url = "https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/beef.png";
    } else {
      disp_recipe = "Bison"
      image_url = "https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/bison.png"
    }
      return (
        <main>
      <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please enter your destination ZIP code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the ZIP code your product will be shipped to. This is used to determine
            shipping costs.
          </DialogContentText>
          
          <TextField
      error={errorState}
      helperText={errorState ? "Your entry was not valid" : ""}
      autoFocus
      margin="dense"
      id="zipcode"
      label="ZIP code"
      type="email"
      fullWidth
      variant="standard"
      onChange={handleTextChange}
    />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => checkZipCodes(days)}>Continue to Checkout</Button>
        </DialogActions>
      </Dialog>
    </div>
          <Card sx={{ minWidth: 275 }}>
            <CardActionArea onClick={() => handleClickOpen(7)}>       
              <CardContent>
              <img
                  height={height}
                  src={image_url}
                  alt="green iguana"
                  layout="fixed"
              />
                <Typography gutterBottom variant="h5" component="div">
                  {disp_recipe}, 7 day mix
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${Math.round(((price*7) + Number.EPSILON) * 100) / 100}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ minWidth: 275 }}>
            <CardActionArea onClick={() => handleClickOpen(14)}>       
              <CardContent>
              <img
                  height={height}
                  src={image_url}
                  alt="green iguana"
              />
                <Typography gutterBottom variant="h5" component="div">
                  {disp_recipe}, 14 day mix
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${Math.round(((price*14) + Number.EPSILON) * 100) / 100}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ minWidth: 275 }}>
            <CardActionArea onClick={() => handleClickOpen(30)}>       
              <CardContent>
              <img
                  height={height}
                  src={image_url}
              />
                <Typography gutterBottom variant="h5" component="div">
                  {disp_recipe}, 30 day mix
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${Math.round(((price*30) + Number.EPSILON) * 100) / 100}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <AppBar position="sticky" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <IconButton href="http://braxtonsnaturaldogfood.com/">
              <ChevronLeftIcon>  </ChevronLeftIcon>
            </IconButton>
          </Toolbar>
        </AppBar>
        </main>
      )
      /*
  } else {
    return(
      <main>
        <h1>That was an error.</h1>
        <p>Did you get here by typing this page into your address bar? If so, please follow this link back to the beginning of the form.</p>
        <Link to="/selectWeight">
          Form start
        </Link>
      </main>
    )    
  
  }*/
}
  
  
  export default DisplayOptionsPage;